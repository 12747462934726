<template>
	<div>
	<contentHeader title="Data Detail Produk" />
	<div class="content-header">
		<div class="container-fluid">
			<div class="row justify-content-center">
				<div class="col">
					<div class="card">
						<div class="card-header row d-flex align-items-center">
	            			<h3 class="card-title col-6">Table Data Detail Produk</h3>
							<div class="col-6 text-right">
								<el-button type="primary" plain @click="dialogVisible = true">Tambah</el-button>	
	            			</div>
	            		</div>
	            		<div class="card-body">
	            			<el-tabs v-model="activeName" type="card">
                                <el-tab-pane name="pabrik" label="Pabrik">
									<p style="user-select: none;">Data Pabrik</p>
									<el-table v-loading="loading_state" :data="data_table.pabrik" style="width: 100%" border>
										<el-table-column type="index" label="No" width="50" />
										<el-table-column prop="nama" label="Nama" min-width="180" />
										<el-table-column fixed="right" label="Operations" width="200">
											<template #default="scope">
    											<el-button type="primary" size="small" @click="getDataUpdate(scope.row)" plain>Edit</el-button>
												<el-popconfirm
													v-if="level_jabatan != 'staff'"
													confirm-button-text="Ya"
													cancel-button-text="Tidak"
													:icon="InfoFilled"
													icon-color="red"
													title="Hapus data?"
													@confirm="handleDelete(scope.row.id)"
												>
													<template #reference>
    													<el-button type="danger" size="small" plain>Hapus</el-button>
													</template>
												</el-popconfirm>
											</template>
										</el-table-column>
									</el-table>
								</el-tab-pane>
                                <el-tab-pane name="kategori" label="Kategori">
									<p style="user-select: none;">Data Kategori</p>
									<el-table v-loading="loading_state" :data="data_table.kategori" style="width: 100%" border>
										<el-table-column type="index" label="No" width="50" />
										<el-table-column prop="nama" label="Nama" min-width="180" />
										<el-table-column fixed="right" label="Operations" width="200">
											<template #default="scope">
    											<el-button type="primary" size="small" plain>Edit</el-button>
												<el-popconfirm
													v-if="level_jabatan != 'staff'"
													confirm-button-text="Ya"
													cancel-button-text="Tidak"
													:icon="InfoFilled"
													icon-color="red"
													title="Hapus data?"
													@confirm="handleDelete(scope.row.id)"
												>
													<template #reference>
    													<el-button type="danger" size="small" plain>Hapus</el-button>
													</template>
												</el-popconfirm>
											</template>
										</el-table-column>
									</el-table>
								</el-tab-pane>
                                <el-tab-pane name="golongan" label="Golongan">
									<p style="user-select: none;">Data Golongan</p>
									<el-table v-loading="loading_state" :data="data_table.golongan" style="width: 100%" border>
										<el-table-column type="index" label="No" width="50" />
										<el-table-column prop="nama" label="Nama" min-width="180" />
										<el-table-column fixed="right" label="Operations" width="200">
											<template #default="scope">
    											<el-button type="primary" size="small" plain>Edit</el-button>
												<el-popconfirm
													v-if="level_jabatan != 'staff'"
													confirm-button-text="Ya"
													cancel-button-text="Tidak"
													:icon="InfoFilled"
													icon-color="red"
													title="Hapus data?"
													@confirm="handleDelete(scope.row.id)"
												>
													<template #reference>
    													<el-button type="danger" size="small" plain>Hapus</el-button>
													</template>
												</el-popconfirm>
											</template>
										</el-table-column>
									</el-table>
								</el-tab-pane>
                                <el-tab-pane name="kandungan" label="Kandungan">
									<p style="user-select: none;">Data Kandungan</p>
									<el-table v-loading="loading_state" :data="data_table.kandungan" style="width: 100%" border>
										<el-table-column type="index" label="No" width="50" />
										<el-table-column prop="nama" label="Nama" min-width="180" />
										<el-table-column fixed="right" label="Operations" width="200">
											<template #default="scope">
    											<el-button type="primary" size="small" plain>Edit</el-button>
												<el-popconfirm
													v-if="level_jabatan != 'staff'"
													confirm-button-text="Ya"
													cancel-button-text="Tidak"
													:icon="InfoFilled"
													icon-color="red"
													title="Hapus data?"
													@confirm="handleDelete(scope.row.id)"
												>
													<template #reference>
    													<el-button type="danger" size="small" plain>Hapus</el-button>
													</template>
												</el-popconfirm>
											</template>
										</el-table-column>
									</el-table>
								</el-tab-pane>
                                <el-tab-pane name="jenis" label="Jenis">
									<p style="user-select: none;">Data Jenis</p>
									<el-table v-loading="loading_state" :data="data_table.jenis" style="width: 100%" border>
										<el-table-column type="index" label="No" width="50" />
										<el-table-column prop="nama" label="Nama" min-width="180" />
										<el-table-column fixed="right" label="Operations" width="200">
											<template #default="scope">
    											<el-button type="primary" size="small" plain>Edit</el-button>
												<el-popconfirm
													v-if="level_jabatan != 'staff'"
													confirm-button-text="Ya"
													cancel-button-text="Tidak"
													:icon="InfoFilled"
													icon-color="red"
													title="Hapus data?"
													@confirm="handleDelete(scope.row.id)"
												>
													<template #reference>
    													<el-button type="danger" size="small" plain>Hapus</el-button>
													</template>
												</el-popconfirm>
											</template>
										</el-table-column>
									</el-table>
								</el-tab-pane>
                                <el-tab-pane name="satuan" label="Satuan">
									<p style="user-select: none;">Data Satuan</p>
									<el-table v-loading="loading_state" :data="data_table.satuan" style="width: 100%" border>
										<el-table-column type="index" label="No" width="50" />
										<el-table-column prop="nama" label="Nama" min-width="180" />
										<el-table-column fixed="right" label="Operations" width="200">
											<template #default="scope">
    											<el-button type="primary" size="small" plain>Edit</el-button>
												<el-popconfirm
													v-if="level_jabatan != 'staff'"
													confirm-button-text="Ya"
													cancel-button-text="Tidak"
													:icon="InfoFilled"
													icon-color="red"
													title="Hapus data?"
													@confirm="handleDelete(scope.row.id)"
												>
													<template #reference>
    													<el-button type="danger" size="small" plain>Hapus</el-button>
													</template>
												</el-popconfirm>
											</template>
										</el-table-column>
									</el-table>
								</el-tab-pane>
                                <el-tab-pane name="lokasi" label="Lokasi">
									<p style="user-select: none;">Data Lokasi</p>
									<el-table v-loading="loading_state" :data="data_table.lokasi" style="width: 100%" border>
										<el-table-column type="index" label="No" width="50" />
										<el-table-column prop="nama" label="Nama" min-width="180" />
										<el-table-column fixed="right" label="Operations" width="200">
											<template #default="scope">
    											<el-button type="primary" size="small" plain>Edit</el-button>
												<el-popconfirm
													v-if="level_jabatan != 'staff'"
													confirm-button-text="Ya"
													cancel-button-text="Tidak"
													:icon="InfoFilled"
													icon-color="red"
													title="Hapus data?"
													@confirm="handleDelete(scope.row.id)"
												>
													<template #reference>
    													<el-button type="danger" size="small" plain>Hapus</el-button>
													</template>
												</el-popconfirm>
											</template>
										</el-table-column>
									</el-table>
								</el-tab-pane>
                            </el-tabs>
	            		</div>
	            		<div class="card-footer">
							
	            		</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<el-dialog
		v-model="dialogVisible"
		title="Tambah Data"
		width="30%"
		:before-close="handleClose"
	>
		<el-form :model="data_baru" label-position="top">
			<el-form-item >
				<label>Jenis Data</label>
				<el-select v-model="data_baru.jenis_data" style="width: 100%;">
					<el-option value="pabrik">Pabrik</el-option>
					<el-option value="kategori">Kategori</el-option>
					<el-option value="golongan">Golongan</el-option>
					<el-option value="kandungan">Kandungan</el-option>
					<el-option value="jenis">Jenis</el-option>
					<el-option value="satuan">Satuan</el-option>
					<el-option value="lokasi">Lokasi</el-option>
				</el-select>
			</el-form-item>
			<el-form-item>
				<label>Nama</label>
				<el-input type="text" v-model="data_baru.nama"></el-input>
			</el-form-item>
		</el-form>
		<template #footer>
		<span class="dialog-footer">
			<el-button @click="dialogVisible = false">Batal</el-button>
			<el-button type="primary" @click="handleSubmit" :loading="loading_submit">Simpan</el-button>
		</span>
		</template>
	</el-dialog>

	<el-dialog
		v-model="dialogVisibleUpdate"
		title="Update Data"
		width="30%"
		:before-close="handleClose"
	>
		<el-form :model="dataUpdate" label-position="top">
			<el-form-item >
				<label>Jenis Data</label>
				<el-select v-model="dataUpdate.jenis_data" style="width: 100%;" disabled>
					<el-option value="pabrik">Pabrik</el-option>
					<el-option value="kategori">Kategori</el-option>
					<el-option value="golongan">Golongan</el-option>
					<el-option value="kandungan">Kandungan</el-option>
					<el-option value="jenis">Jenis</el-option>
					<el-option value="satuan">Satuan</el-option>
					<el-option value="lokasi">Lokasi</el-option>
				</el-select>
			</el-form-item>
			<el-form-item>
				<label>Nama</label>
				<el-input type="text" v-model="dataUpdate.nama"></el-input>
			</el-form-item>
		</el-form>
		<template #footer>
		<span class="dialog-footer">
			<el-button @click="dialogVisibleUpdate = false">Batal</el-button>
			<el-button type="primary" @click="handleUpdate" :loading="loadingUpdate">Simpan</el-button>
		</span>
		</template>
	</el-dialog>
		
	</div>
</template>

<script setup>
import ContentHeader from '@/components/ContentHeader'

import { onMounted, ref, reactive, computed } from 'vue'
import axios from 'axios'
import { ElMessageBox } from 'element-plus'
import { ElMessage } from 'element-plus'
import store from '@/store'

import { InfoFilled } from '@element-plus/icons-vue'

const level_jabatan = computed(() => store.getters['auth/level_jabatan'])

const activeName = ref('pabrik')
const data_table = reactive({
	pabrik		: [],
	kategori	: [],
	golongan	: [],
	kandungan	: [],
	jenis		: [],
	satuan		: [],
	lokasi		: []
})

const loading_state = ref(false)
const getPabrik  = async () => {
	loading_state.value = true
	await axios.get(`api/detail_produk/getWhereJenis/pabrik`)
	.then((response) => {
		data_table.pabrik = response.data
		loading_state.value = false
	})
	.catch(() => {
		data_table.pabrik = []
		loading_state.value = false
	})
	
}

const getKategori  = async () => {
	loading_state.value = true
	await axios.get(`api/detail_produk/getWhereJenis/kategori`)
	.then((response) => {
		data_table.kategori = response.data
		loading_state.value = false
	})
	.catch(() => {
		data_table.kategori = []
		loading_state.value = false
	})
	
}

const getGolongan  = async () => {
	loading_state.value = true
	await axios.get(`api/detail_produk/getWhereJenis/golongan`)
	.then((response) => {
		data_table.golongan = response.data
		loading_state.value = false
	})
	.catch(() => {
		data_table.golongan = []
		loading_state.value = false
	})
	
}

const getKandungan  = async () => {
	loading_state.value = true
	await axios.get(`api/detail_produk/getWhereJenis/kandungan`)
	.then((response) => {
		data_table.kandungan = response.data
		loading_state.value = false
	})
	.catch(() => {
		data_table.kandungan = []
		loading_state.value = false
	})
	
}

const getJenis  = async () => {
	loading_state.value = true
	await axios.get(`api/detail_produk/getWhereJenis/jenis`)
	.then((response) => {
		data_table.jenis = response.data
		loading_state.value = false
	})
	.catch(() => {
		data_table.jenis = []
		loading_state.value = false
	})
	
}

const getSatuan  = async () => {
	loading_state.value = true
	await axios.get(`api/detail_produk/getWhereJenis/satuan`)
	.then((response) => {
		data_table.satuan = response.data
		loading_state.value = false
	})
	.catch(() => {
		data_table.satuan = []
		loading_state.value = false
	})
	
}

const getLokasi  = async () => {
	loading_state.value = true
	await axios.get(`api/detail_produk/getWhereJenis/lokasi`)
	.then((response) => {
		data_table.lokasi = response.data
		loading_state.value = false
	})
	.catch(() => {
		data_table.lokasi = []
		loading_state.value = false
	})
	
}

onMounted(() => {
	getPabrik()
	getKategori()
	getGolongan()
	getKandungan()
	getJenis()
	getSatuan()
	getLokasi()
})

const data_baru = reactive({
	jenis_data : null,
	nama	   : null
})
const loading_submit = ref(false)
const dialogVisible = ref(false)

const handleClose = () => {
  ElMessageBox.confirm('Tutup Tampilan?')
    .then(() => {
      dialogVisible.value = false
    })
    .catch(() => {
      // catch error
    })
}

const handleSubmit = () => {
	loading_submit.value = true
	axios.post(`api/detail_produk/create`, data_baru)
	.then(() => {
		loading_submit.value 	= false
		dialogVisible.value 	= false
		getPabrik()
		getKategori()
		getGolongan()
		getKandungan()
		getJenis()
		getSatuan()
		getLokasi()
		data_baru.jenis_data 	= null
		data_baru.nama 			= null
		ElMessage({
			message: 'Data berhasil ditambahkan.',
			type: 'success',
		})
	})
	.catch(() => {
		loading_submit.value = false
  		ElMessage.error('Gagal menambahkan data.')
	})
}

const handleDelete = (id) => {
	loading_state.value = true
	axios.delete(`api/detail_produk/destroy/${id}`)
	.then(() => {
		getPabrik()
		getKategori()
		getGolongan()
		getKandungan()
		getJenis()
		getSatuan()
		getLokasi()
		ElMessage({
			message: 'Berhasil menghapus data.',
			type: 'success',
		})
		loading_state.value = false
	})
	.catch(() => {
		loading_submit.value = false
  		ElMessage.error('Gagal menghapus data.')
	})
}

const dialogVisibleUpdate = ref(false)
const dataUpdate 	= ref(null)
const loadingUpdate = ref(false)

const getDataUpdate = (data) => {
	dataUpdate.value = data
	dialogVisibleUpdate.value = true
}

const handleUpdate = () => {
	loadingUpdate.value = true
	axios.put(`api/detail_produk/update/${dataUpdate.value.id}`, dataUpdate.value)
	.then(() => {
		getPabrik()
		getKategori()
		getGolongan()
		getKandungan()
		getJenis()
		getSatuan()
		getLokasi()
		ElMessage({
			message: 'Data berhasil diupdate.',
			type: 'success',
		})
	})
	.catch(() => {
  		ElMessage.error('Gagal update data.')
	})
	.finally(() => {
		loadingUpdate.value 		= false
		dialogVisibleUpdate.value 	= false
		console.log(loadingUpdate.value)
	})
}
</script>
